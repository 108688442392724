import React from "react";
import { MenuBar } from "../Layout/MenuBar";
import { Footer } from "../Layout/Footer";
import { ListItemText, Typography, List, ListItem } from "@mui/material";
import { Container, Box } from "@mui/system";
import { Link, Outlet } from "react-router-dom";

export const Services = () => (
  <div className="body">
    <MenuBar />
    <Container sx={{ padding: "6.5%", height: "100% " }}>
      <Box sx={{ paddingTop: "0.5rem" }}>
        <Typography variant="h2" className="acm-header">
          Services
        </Typography>
      </Box>
      <Box sx={{ paddingTop: "2.5rem" }}>
        <Typography variant="h5" className="acm-header">
          Why engaging with an independent consultant can work for you!
        </Typography>
        <Box>
          <Typography variant="body1">
            An independent consultant is an individual whose extensive
            experience in a specific field is able to be tapped into by a
            business or individual. Consultants play an advisory role, offering
            insight to guide the financial and business decisions, shape new
            direction, inform of changes and identify areas which might require
            more insight for their client. ACM offers a range of consultancy
            options including:
            <Typography variant="overline" className="acm-subheader">
              <List
                sx={{
                  width: "100%",
                  maxWidth: 1600,
                  bgcolor: "background.paper",
                  textAlign: "center",
                }}
              >
                <ListItem>
                  <ListItemText
                    sx={{ display: "list-item", textAlign: "center" }}
                    primary="Strategy Consultancy"
                  >
                    Strategy Consultancy
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText
                    sx={{ display: "list-item", textAlign: "center" }}
                    primary="Management Consultancy"
                  >
                    Management Consultancy
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ display: "list-item", textAlign: "center" }}>
                  <ListItemText
                    sx={{ display: "list-item", textAlign: "center" }}
                  >
                    Operations Consultancy
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText
                    sx={{ display: "list-item", textAlign: "center" }}
                  >
                    Financial Advisory Consultancy for Removal Costs
                  </ListItemText>
                </ListItem>
              </List>
            </Typography>
          </Typography>
        </Box>
        <Box paddingTop={"2rem"}>
          <Typography variant="h5" className="acm-header">
            What is Good or What is Bad?
          </Typography>
          <Box>
            <Typography variant="body1">
              Ideally, the insight and guidance provided by a consultant will
              make better decisions for the client and a greater success in the
              project on completion. Independent consultants can work in any
              field using their backgrounds and considerable know-how to shed
              light on any gaps or deficiencies in the scope of works required.
            </Typography>
          </Box>
        </Box>
        <Box paddingTop={"3.5rem"}>
          <Typography variant="h2" className="acm-header">
            More Services
          </Typography>
          <Box sx={{ paddingTop: "1.5rem" }}>
            <Typography variant="h5" className="acm-header">
              Asbestos Assessor - Class A & B Asbestos Removals
            </Typography>
            <Typography variant="h6" className="acm-subheader">
              <List
                sx={{
                  width: "100%",
                  maxWidth: 1600,
                  bgcolor: "background.paper",
                  textAlign: "center",
                }}
              >
                <ListItem>
                  <ListItemText
                    sx={{ display: "list-item", textAlign: "center" }}
                    primary="Oversee and monitor asbestos removals"
                  >
                    Oversee and monitor asbestos removals
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText
                    sx={{ display: "list-item", textAlign: "center" }}
                    primary="Baseline air monitoring"
                  >
                    Baseline air monitoring
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText
                    sx={{ display: "list-item", textAlign: "center" }}
                    primary="Background and clearance air monitoring"
                  >
                    Background and clearance air monitoring
                  </ListItemText>
                </ListItem>
              </List>
            </Typography>
          </Box>

          <Typography variant="h5" className="acm-header">
            Asbestos Surveying - Industrial, Commercial and Residential
          </Typography>
          <Typography variant="h6" className="acm-subheader">
            <List
              sx={{
                width: "100%",
                maxWidth: 1600,
                bgcolor: "background.paper",
                textAlign: "center",
              }}
            >
              <ListItem>
                <ListItemText
                  sx={{ display: "list-item", textAlign: "center" }}
                  primary="Demolition Surveys"
                >
                  Demolition Surveys
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText
                  sx={{ display: "list-item", textAlign: "center" }}
                  primary="Renovation Surveys"
                >
                  Renovation Surveys
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText
                  sx={{ display: "list-item", textAlign: "center" }}
                  primary="Management and Change Management Surveys"
                >
                  Management and Change Management Surveys
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText
                  sx={{ display: "list-item", textAlign: "center" }}
                  primary=" Soil Analysis and Contaminated Land Surveying"
                >
                  Soil Analysis and Contaminated Land Surveying
                </ListItemText>
              </ListItem>
            </List>
          </Typography>
          <Typography
            variant="h5"
            className="acm-header"
            sx={{ paddingTop: "1.5rem" }}
          >
            Asbestos Awareness Training
          </Typography>
          <Typography
            variant="h5"
            className="acm-header"
            sx={{ paddingTop: "1.5rem" }}
          >
            Project Management - Site Oversee and Consultation
          </Typography>
          <Typography
            variant="h5"
            className="acm-header"
            sx={{ paddingTop: "1.5rem" }}
          >
            ARCP Overview including risk registers and review of ARCP's
          </Typography>
          <Typography
            variant="h5"
            className="acm-header"
            sx={{ paddingTop: "1.5rem" }}
          >
            WorkSafe Notifications
          </Typography>
        </Box>
        <Box sx={{ paddingTop: "1.5rem" }}>
          <Link to={`/contact`}>
            <Typography
              variant="body1"
              textAlign="center"
              sx={{ color: "#1c3fa6" }}
            >
              Book Now
            </Typography>
          </Link>
        </Box>
        <Outlet />
      </Box>
    </Container>
    <Footer />
  </div>
);
export default Services;
