import React from "react";
import { MenuBar } from "../Layout/MenuBar";
import { Footer } from "../Layout/Footer";
import { Container, Box } from "@mui/system";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import house from "../Assets/WKS-2.jpg";
import industrial from "../Assets/WKS-1.jpg";

export const AboutAsbestos = () => (
  <div className="body">
    <MenuBar />
    <Container sx={{ padding: "6.5%" }}>
      <Box sx={{ paddingTop: "0.5rem" }}>
        <Typography variant="h2" className="acm-header">
          What is Asbestos?
        </Typography>
        <Typography variant="body1">
          Asbestos is a naturally occurring fibrous silicate material There are
          six types all of which are composed of long and thing fibrous
          crystals, each fibre being composed of many microscopic "fibrils" that
          can be released into the atmosphere by abrasion and other processes.
          The use of asbestos for construction and fire-proofing has been made
          illegal in many countries<sup href="">2</sup>. Despite this, at least
          100,000 people are thought to die each year from diseases related to
          asbestos exposure. In part, this is because many older buildings still
          contain asbestos; in addition, the consequences of exposure can take
          decades to arise<sup>6</sup>
          <sup>7</sup>
          The most common diseases associated with chronic asbestos exposure are
          asbestosis (scarring of the lungs due to asbestos inhalation) and
          mesothelioma (a type of cancer).
        </Typography>
      </Box>
      <Box sx={{ paddingTop: "3rem" }}>
        <Typography variant="h4" className="acm-header">
          Where is Asbestos Found?
        </Typography>
        <Typography variant="body1">
          Asbestos can be found in many common household and industrial
          settings. Knowing how to correctly identify and dispose of asbestos
          could make all the difference to your quality of life.
        </Typography>
        <Box sx={{ paddingTop: "3rem" }}>
          <Typography variant="h5" className="acm-header">
            Asbestos in the Home
          </Typography>
          <Typography variant="body1">
            Areas where asbestos can be found in a residential house include:
          </Typography>
          <Typography variant="h6" className="acm-subheader">
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                textAlign: "center",
              }}
            >
              <ListItem>
                <ListItemText
                  sx={{ display: "list-item", textAlign: "center" }}
                >
                  The centre of fire doors
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText
                  sx={{ display: "list-item", textAlign: "center" }}
                >
                  Interior and exterior walls
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText
                  sx={{ display: "list-item", textAlign: "center" }}
                >
                  Flooring - especially lino flooring in wet areas such as
                  bathrooms, laundries and kitchens.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText
                  sx={{ display: "list-item", textAlign: "center" }}
                >
                  Roofs
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText
                  sx={{ display: "list-item", textAlign: "center" }}
                >
                  Hot water tanks
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText
                  sx={{ display: "list-item", textAlign: "center" }}
                >
                  Insulation and lagging
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText
                  sx={{ display: "list-item", textAlign: "center" }}
                >
                  Gutters and downpipes
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText
                  sx={{ display: "list-item", textAlign: "center" }}
                >
                  Lift shafts
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText
                  sx={{ display: "list-item", textAlign: "center" }}
                >
                  Ceilings
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText
                  sx={{ display: "list-item", textAlign: "center" }}
                >
                  Electrical fuse boards
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText
                  sx={{ display: "list-item", textAlign: "center" }}
                >
                  Internal water pipes
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText
                  sx={{ display: "list-item", textAlign: "center" }}
                >
                  Around windows and door frames.
                </ListItemText>
              </ListItem>
            </List>
          </Typography>
        </Box>
        <Box>
          <img
            src={house}
            alt="The potential sources for asbestos in the home."
            style={{ maxWidth: "100%", minWidth: "300px", height: "auto" }}
          />
        </Box>
      </Box>
      <Box>
        <Box sx={{ paddingTop: "3rem" }}>
          <Typography variant="h5" className="acm-header">
            Asbestos in Industrial Settings
          </Typography>
          <Typography variant="body1">
            Asbestos can also be found as part of fencing, water mains, and in
            soil. It can even be found in telecommunications or electrical pits.
            Asbestos can be located in a variety of places in a commercial
            building. As it’s a fire-resistant and hard-wearing material, it is
            commonly found in places that may get hot or wet. This includes the
            centre of fire doors and interior and exterior walls. If you are in
            doubt as to whether a part of your building contains asbestos or
            not, it is best to err on the side of caution and assume it does. If
            you think that there is asbestos in your building then download our
            management plan fact sheet which will help you manage your duties
            under the Health and Safety at Work (Asbestos) Regulations.
          </Typography>
          <Box>
            <img
              src={industrial}
              alt="The potential sources for asbestos in the home."
              style={{ maxWidth: "100%", minWidth: "300px", height: "auto" }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ paddingTop: "3rem" }}>
        <Typography variant="h4" className="acm-header">
          Key Responsibilities
        </Typography>
        <Typography
          variant="h5"
          className="acm-header"
          sx={{ paddingTop: "1.5rem" }}
        >
          Key duties for surveyors and assessors
        </Typography>
        <Typography variant="body1">
          There should be no conflict of interest between the surveyor,
          removalist and assessor that could influence the outcome of the final
          clearance inspection. If asbestos surveyors, assessors, or removalists
          witness unsafe practice in a workplace, they should: – NOTE: Notify
          WorkSafe of the unsafe practice by calling 0800 030 040. – Provide
          details of the unsafe practice including location, date, time and
          people involved.
          <Typography
            variant="h5"
            className="acm-header"
            sx={{ paddingTop: "1.5rem" }}
          >
            Key duties for surveyors
          </Typography>
          <Typography variant="body1">
            Choose a survey type that is appropriate for the client’s needs. –
            Locate and identify asbestos as appropriate for the type of survey
            (Management, Refurbishment or Demolition). Design the survey so that
            it can be used by the client or by the removalist to create an
            asbestos removal control plan. – Talk to the client about how the
            survey fits into the process for managing asbestos risks. Competency
            expectations WorkSafe recommends that at a minimum surveyors should:
            – have a minimum of six months practical experience of carrying out
            asbestos surveys under the supervision of experienced and suitably
            qualified personnel, and – be able to provide evidence that they
            have completed at least three management survey reports, and – be
            able to provide evidence that they have completed at least one
            pre-demolition survey alongside another more experienced surveyor,
            and – hold a British Occupational Hygiene Society IP402 Building
            Surveys and Bulk Sampling for Asbestos qualification, or equivalent.
          </Typography>{" "}
          <Typography
            variant="h5"
            className="acm-header"
            sx={{ paddingTop: "1.5rem" }}
          >
            Key duties for assessors
          </Typography>{" "}
          <Typography variant="body1">
            {" "}
            Monitor the quality of the air before, during and after asbestos
            removal to make sure the work environment is safe and that controls
            are effective. – Tell the client immediately if respirable asbestos
            fibres exceed 0.01 fibres per millilitre of air. – Provide the
            results of any air monitoring or surface testing to the client. –
            Carry out clearance inspections when asbestos removal work has been
            completed. Competency requirements Assessors must: – hold relevant
            industry knowledge acquired through training and experience, and –
            hold a certificate in relation to an asbestos assessment training
            course specified by WorkSafe, or – hold a tertiary qualification in
            occupational health and safety, occupational hygiene, science or
            environmental health. Surveyor Assessor Checks a site for the
            presence of asbestos and advises on a removal plan Removes the
            asbestos from all known locations Monitors the air quality
            throughout the asbestos removal process to ensure the work
            environment is safe.
          </Typography>
        </Typography>
      </Box>
    </Container>
    <Footer />
  </div>
);

export default AboutAsbestos;
