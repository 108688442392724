import React from "react";
import { MenuBar } from "../Layout/MenuBar";
import { Footer } from "../Layout/Footer";
import { Typography } from "@mui/material";
import { Container, Box } from "@mui/system";

export const Testimonials = () => (
  <div className="body">
    <MenuBar />
    <Container sx={{ padding: "6.5%", height: "95vh " }} maxWidth="xl">
      <Box sx={{ paddingTop: "0.95rem", marginBottom: "80%" }}>
        <Typography variant="h2" className="acm-header">
          Testimonials
        </Typography>
      </Box>
    </Container>
    <Footer />
  </div>
);
