import React from "react";
import { MenuBar } from "../Layout/MenuBar";
import { Footer } from "../Layout/Footer";
import { Container } from "@mui/system";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const Conditions = () => (
  <div id="privacy" className="body">
    <MenuBar />
    <Container sx={{ padding: "5%" }}>
      <Typography
        variant="h2"
        className="acm-header"
        sx={{ margin: "115px", marginBottom: "50px" }}
      >
        Terms of use
      </Typography>
      <Typography variant="h5" className="acm-subheader" margin={0}>
        By viewing and using this site, you agree to these terms of use.
      </Typography>
      <Typography
        variant="h4"
        className="acm-header"
        sx={{ marginTop: "1.5rem" }}
      >
        Agreement to terms{" "}
      </Typography>
      <Typography
        variant="body2"
        sx={{ marginLeft: "115px", marginRight: "115px" }}
      >
        Welcome to acm.nz These terms apply to the entire contents of the site,
        to any web feeds produced by the site and to any feedback or submissions
        provided through the site. By viewing and using the site, you will be
        deemed to agree to these terms of use.
      </Typography>
      <Typography
        variant="h4"
        className="acm-header"
        sx={{ marginTop: "1.5rem" }}
      >
        {" "}
        Disclaimer
      </Typography>
      <Typography
        variant="body2"
        sx={{ marginLeft: "115px", marginRight: "115px" }}
      >
        ACM disclaims and excludes all liability for any claim, loss, demand or
        damages of any kind whatsoever (including for negligence) arising out of
        or in connection with the use of either this website or the information,
        content or materials included on this site or on any website we link to.
        Social share buttons We use ‘buttons’ that link to specific social media
        platforms. These ‘buttons’ enable visitors to share pages from our
        website to their social media pages. We do not own these social media
        channels and encourage you to read their privacy policy and conditions
        of use. Please protect your personal information and others by not
        publishing any personal information such as names and addresses. Adding
        link sharing of our pages to social media platforms does not imply we
        endorse these sites or that there is any obligation for you to share to
        platforms you belong to or have not already joined.
      </Typography>
      <Typography
        variant="h4"
        className="acm-header"
        sx={{ marginTop: "1.5rem" }}
      >
        Continuity of Service{" "}
      </Typography>
      <Typography
        variant="body2"
        sx={{ marginLeft: "115px", marginRight: "115px" }}
      >
        ACM makes no warranty or representation, express or implied, as to
        continuity of service. It reserves the right to suspend, terminate or
        otherwise alter access to some or all of the site’s services at any time
        and without notice.
      </Typography>
      <Typography
        variant="h4"
        className="acm-header"
        sx={{ marginTop: "1.5rem" }}
      >
        {" "}
        Contributions to acm.nz{" "}
      </Typography>{" "}
      <Typography
        variant="body2"
        sx={{ marginLeft: "115px", marginRight: "115px" }}
      >
        Other than personal information, which is covered under the privacy
        policy, any material you transmit or post to the site is considered
        non-confidential. This includes feedback or submissions you provide.{" "}
      </Typography>
      <Typography variant="h5">
        <Link
          to={"/privacy"}
          className="acm-header"
          sx={{ marginTop: "1.5rem" }}
        >
          Privacy
        </Link>
      </Typography>
      <Typography
        variant="body2"
        sx={{ marginLeft: "115px", marginRight: "115px" }}
      >
        We do not claim to own your copyright material. You do, however, grant
        ACM a non-exclusive, royalty-free and irrevocable licence to: publish
        such material in any media and in any format such, as testimonials,
        including: on this or any other website in hard copy publications, in
        email notifications or alerts in web feeds, and sub-license such
        material for re-use by others on the same terms as the licence you’ve
        granted ACM.
      </Typography>
      <Typography
        variant="h4"
        className="acm-header"
        sx={{ marginTop: "1.5rem" }}
      >
        Revisions to these terms of use{" "}
      </Typography>
      <Typography
        variant="body2"
        sx={{ marginLeft: "115px", marginRight: "115px" }}
      >
        ACM may revise these terms of use at any time. Any such revisions take
        effect when this page is published. Contact us for more information.
        Email: rich@acm.nz
      </Typography>
    </Container>
    <Footer />
  </div>
);
