import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AboutAsbestos } from "./Views/AboutAsbestos";
import { Conditions } from "./Views/Conditions";
import { ErrorPage } from "./Views/ErrorPage";
import { Contact } from "./Views/Contact";
import { Landing } from "./Views/Landing";
import { Services } from "./Views/Services";
import { PrivacyPage } from "./Views/Privacy";
import { Testimonials } from "./Views/Testimonials";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    errorElement: <ErrorPage />,
  },
  {
    path: `/about-asbestos`,
    element: <AboutAsbestos />,
    errorElement: <ErrorPage />,
  },
  { path: "/contact", element: <Contact />, errorElement: <ErrorPage /> },
  { path: "/services", element: <Services />, errorElement: <ErrorPage /> },
  {
    path: "/testimonials",
    element: <Testimonials />,
    errorElement: <ErrorPage />,
  },
  { path: "/terms", element: <Conditions />, errorElement: <ErrorPage /> },
  {
    path: "/privacy",
    element: <PrivacyPage />,
    errorElement: <ErrorPage />,
  },
]);

export const ACM = () => <RouterProvider router={router} />;

export default ACM;
