import React from "react";
import { MenuBar } from "../Layout/MenuBar";
import { Item } from "../Layout/Item";
import { Footer } from "../Layout/Footer";
import { Box, Container } from "@mui/system";
import { Button, MenuItem, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { sendData } from "../Utils/testEmailer";

export const Contact = () => {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    sendData(data);
  };
  const services = [
    "ARCP Services",
    "Asbestos Assessment",
    "Asbestos Awareness Training",
    "Asbestos Consultancy",
    "Project Management",
    "Asbestos Survey",
    "Soil Analysis and Contaminated Land Surveys ",
    "WorkSafe Notifications",
  ];

  return (
    <div className="body">
      <MenuBar />
      <Container sx={{ padding: "6.5%", marginBottom: "7rem" }}>
        <Box sx={{ paddingTop: "0.5rem" }}>
          <Typography variant="h2" className="acm-header">
            Contact
          </Typography>
        </Box>
      </Container>
      <Box
        component="form"
        onSubmit={handleSubmit((data) => console.log(data))}
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
      >
        <Item>
          <div>
            <TextField
              required
              id="outlined-required"
              label="Required"
              defaultValue="First Name"
              {...register("firstName", { required: true })}
            />

            <TextField
              required
              id="outlined-required"
              label="Required"
              defaultValue="Last Name"
              {...register("lastName", { required: true })}
            ></TextField>
          </div>
          <div>
            <TextField
              required
              id="outlined-required"
              label="Required"
              defaultValue="Email"
              {...register("email", {
                required: true,
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            ></TextField>
            <TextField
              required
              id="outlined"
              defaultValue="Phone Number"
              {...register("phoneNumber", {
                required: false,
                maxLength: 11,
                minLength: 7,
              })}
            ></TextField>
          </div>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 2, width: "45ch" },
            }}
          >
            <div>
              <div>
                <TextField
                  id="outlined-flexible"
                  label="Address"
                  defaultValue="Address"
                  {...register("address", { required: false })}
                />
              </div>
              <TextField
                id="outlined-select"
                select
                label="Select Services"
                defaultValue="Asbestos Consultancy"
                sx={{ textAlign: "left" }}
                {...register("service")}
              >
                {services.map((option, i) => (
                  <MenuItem key={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <TextField
              id="outlined-multiline-flexible"
              label="Description"
              multiline
              maxRows={6}
              {...register("description")}
            />
          </Box>
        </Item>
        <Box sx={{ paddingTop: "2rem" }}>
          <Button
            variant="outlined"
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Send enquiry
          </Button>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};
export default Contact;
