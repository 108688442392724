import { useRouteError } from "react-router-dom";

import React from "react";
import { MenuBar } from "../Layout/MenuBar";
import { Footer } from "../Layout/Footer";
import { Box, Container } from "@mui/system";

export const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page" className="body">
      <MenuBar />
      <Container sx={{ padding: "5%" }}>
        <h1 className="acm-header">Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      </Container>
      <Box sx={{ marginBottom: "auto", paddingBottom: "4rem" }} />
      <Footer />
    </div>
  );
};

export default ErrorPage;
