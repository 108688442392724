import React, { useState } from "react";
import { Box, Container } from "@mui/system";
import {
  AppBar,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material/";
import IconButton from "@mui/material/IconButton";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { Link as RouterLink, Outlet } from "react-router-dom";
import iconTransparent from "../Assets/icon_transparent.png";
import "../ACM.scss";

export const MenuBar = () => {
  const pages = ["services", "about-asbestos", "contact"];

  const [anchorElNav, setAnchorElNav] = useState(null);
  const handleOpenNavMenu = (e) => {
    setAnchorElNav(e.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const LinkBehavior = React.forwardRef((props, ref) => (
    <RouterLink ref={ref} to="/" {...props} role={undefined} />
  ));

  return (
    <AppBar
      sx={{
        backgroundColor: "#0000;",
        position: "absolute",
        width: "100%",
        paddingBottom: "5px",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <IconButton
            sx={{
              display: { xs: "none", md: "flex" },
              mr: 1,
              paddingLeft: 0,
            }}
          >
            <img src={iconTransparent} alt="logo" style={{ height: "6vh" }} />
          </IconButton>
          <Typography
            className="acm-menu"
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              textDecoration: "none",
            }}
          >
            ACM
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuOutlinedIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page, id) => (
                <MenuItem key={id} component={LinkBehavior} to={`/${page}`}>
                  <Typography
                    textAlign="center"
                    sx={{ color: "#1c3fa6" }}
                    key={id}
                  >
                    {page.replace(/[.-]/g, " ").toUpperCase()}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            className="acm-header"
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 0,
              fontFamily: "monospace",
              fontWeight: 700,
              textDecoration: "none",
            }}
          >
            ACM
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page, id) => (
              <Button
                className="App-menu"
                key={id}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, display: "block" }}
                component={LinkBehavior}
                to={`/${page}`}
              >
                <Typography
                  textAlign="center"
                  sx={{ color: "#1c3fa6" }}
                  key={id}
                >
                  {page.replace(/[.-]/g, " ").toUpperCase()}
                </Typography>
              </Button>
            ))}
          </Box>
          <Outlet />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
