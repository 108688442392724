import React from "react";
import { MenuBar } from "../Layout/MenuBar";
import { Footer } from "../Layout/Footer";
import { Box, Container } from "@mui/system";
import { Link, Typography } from "@mui/material";

export const PrivacyPage = () => (
  <div id="privacy" className="body">
    <MenuBar />
    <Container sx={{ padding: "5%" }}>
      <Typography
        variant="h2"
        className="acm-header"
        sx={{ margin: "115px", marginBottom: "50px" }}
      >
        Privacy Policy
      </Typography>
      <Typography variant="body2" sx={{ margin: "115px" }}>
        We collect personal information from you, including information about
        your: name contact information We collect your personal information in
        order to: contact people if asked to. Providing some information is
        optional. If you choose not to enter contact details, we'll be unable to
        provide advice or services. We keep your information safe by storing it
        in encrypted files and it is only accessed by the sole contractor . You
        have the right to ask for a copy of any personal information we hold
        about you, and to ask for it to be corrected if you think it is wrong.
        If you’d like to ask for a copy of your information, or to have it
        corrected, please contact us at{" "}
        <Link underline="hover" href="mailto:rich@acm.nz">
          rich@acm.nz
        </Link>
      </Typography>
    </Container>
    <Box sx={{ marginBottom: "auto" }} />
    <Footer />
  </div>
);
