import React from "react";
import { Container, Box } from "@mui/system";
import { MenuBar } from "../Layout/MenuBar";
import logo from "../Assets/logo_transparent.svg";
import { Footer } from "../Layout/Footer";

export const Landing = () => {
  return (
    <div className="body">
      <MenuBar />
      <div className="acm-landing">
        <Container sx={{ paddingTop: "40px" }}>
          <img src={logo} className="acm-logo" alt="logo" />
        </Container>
        <Box>
          <h2 className="acm-header">Asbestos Consultancy & Management Ltd</h2>
          <h4 className="acm-subheader">Established 2022</h4>
        </Box>
      </div>
      <Footer />
    </div>
  );
};

export default Landing;
