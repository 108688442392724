import axios from "axios";
const API_PATH = "/cgi-sys/formmail.pl";

export const sendData = (data) => {
  const formData = new FormData();
  formData.set("mame", `${data.firstName} ${data.lastName}`);
  formData.set("email", `${data.email}`);
  formData.set("address", `${data.address}`);
  formData.set("phoneNumber", `${data.contactPhone}`);
  formData.set("service", `${data.service}`);
  formData.set("description", `${data.description}`);

  console.log(formData)
  return axios({
    method: "post",
    url: API_PATH,
    headers: {
      "content-type": "application/json",
    },
    data: formData,
  })
    .then((result) => {
      console.log(result.data);
      console.log(formData.state);
    })
    .catch((error) => ({
      error: error.message,
    }));
};

export default sendData;
