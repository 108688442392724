import React from "react";
import AppBar from "@mui/material/AppBar";
import FacebookIcon from "@mui/icons-material/FacebookTwoTone";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Button, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { Link as RouterLink, Outlet } from "react-router-dom";

export const Footer = () => {
  const LinkBehavior = React.forwardRef((props, ref) => (
    <RouterLink ref={ref} to="/" {...props} role={undefined} />
  ));
  return (
    <AppBar
      sx={{
        color: "#18358c",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        marginTop: "15%",
        zIndex: 1100,
        top: "auto",
        left: "auto",
        right: 0,
        position: "absolute",
      }}
    >
      <Box
        sx={{
          marginRight: "auto",
          display: "flex",
          flexDirection: "column",
          color: "#ffff",
          padding: "1.25rem",
        }}
      >
        <Typography
          variant="overline"
          sx={{
            textAlign: "start",
            marginLeft: "1.25rem",
            fontSize: "1.05rem",
            lineHeight: 1.66,
          }}
        >
          Contact
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            margin: "1rem",
            textAlign: "start",
          }}
        >
          <PhoneAndroidIcon sx={{ fontSize: 30, paddingRight: "2rem" }} />
          <Typography
            variant="body2"
            sx={{
              fontWeight: "300",
              marginBottom: "0.5rem",
              marginTop: "0.5rem",
            }}
          >
            021-487-258
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", margin: "1rem" }}>
          <EmailIcon sx={{ fontSize: 30, paddingRight: "2rem" }} />
          <Typography variant="body2" sx={{ marginBottom: "0.5rem" }}>
            <Link
              underline="hover"
              href="mailto:rich@acm.nz"
              sx={{ cursor: "pointer", color: "#ffff" }}
            >
              rich@acm.nz
            </Link>
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", margin: "1rem" }}>
          <FacebookIcon
            path=""
            sx={{
              fontSize: 30,
              display: "inline-flex",
            }}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            marginTop: "auto",
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
            paddingTop: "1.5rem",
            marginLeft: "0.5rem",
          }}
        >
          <Button
            variant="text"
            sx={{ color: "#ffff" }}
            component={LinkBehavior}
            to="/privacy"
          >
            <Link underline="none">
              <Typography variant="caption" sx={{ color: "#ffff" }}>
                Privacy
              </Typography>
            </Link>
          </Button>
          <Button
            variant="text"
            sx={{ color: "#ffff" }}
            component={LinkBehavior}
            to="/terms"
          >
            <Link underline="none">
              <Typography variant="caption" sx={{ color: "#ffff" }}>
                Terms & Conditions
              </Typography>
            </Link>
          </Button>
        </Box>
        <Outlet />
      </Box>
    </AppBar>
  );
};
